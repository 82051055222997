
































































































































































  import { Component, Vue, Prop } from "vue-property-decorator";
  import Navbar from "@/components/common/Navbar.vue";
  import Illustration from "@/components/common/Illustration.vue";
  import Snackbar from "@/components/common/Snackbar.vue";
  import GamificationService from "@/services/GamificationService";
  import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
  import DataTable from "@/components/common/Tables/DataTable.vue";
  import TextButton from "@/components/common/Buttons/TextButton.vue";
  import SideDrawer from "@/components/common/SideDrawer.vue";
  import { ICouponScheme } from "@/interfaces/CouponScheme";
  import { ILink, ITableHeader } from "@/interfaces/common";
  import { VLayout } from "vuetify/lib";
  import { oldPortalUrl } from "@/envConfig";
  import CouponSchemeService from "@/services/CouponSchemeService";

  @Component({
      components: {
          Illustration,
          Navbar,
          Snackbar,
          ActionablePopup,
          DataTable,
          TextButton,
          SideDrawer,
      },
  })
  export default class Create extends Vue {
      // for illustration
      private description: string = "No Norms Found";
      private subdescription: string = "Please create a Norm to begin with";
      private valid: boolean = false;
      private AvailableCoupons: number = 0;
      private PendingCoupons: number = 0;
      private ConsumedCoupons: number = 0;
      private timeout!: NodeJS.Timeout;
      private message: string = "";
      private snackbar: boolean = false;
      private snackbarColor: string = "success";
      private showSchemesList: boolean = true;
      private info: any = {};
      private defaultItem: any = {};
      //flags for create or edit check
      private createScheme: boolean = true;
      private sidedrawerTitle: string = "";
      //for side drawer
      private sideDrawer: boolean = false;
      private menuItemsActive: Array<ILink> = [];
      private selectedGameId: number = 0;
      private SchemeName: string = "";
      private Schemes: Array<ICouponScheme> = [];
      private totalScheme: number = 0;
      private pages: number = 0;
      private page: number = 1;
      private colorx: string = "#4a5153";
      private dialog: boolean = false;
      private pageLoading: boolean = false;
      private selectedActionMenu: number = -1;
      private confirmationPopup: boolean = false;
      private confirmationPopupTitle: string = "";
      private confirmationPopupSubtitle: string = "";
      private confirmationPopupText: string = "";
      private cancelBtnText: string = "";
      private confirmBtnText: string = "";
      private tableHeaders: Array<ITableHeader> = [
          {
              text: "Scheme Name",
              value: "name",
              sortable: true,
              default: "",
          },
          {
              text: "End Date",
              value: "endDate",
              sortable: false,
              default: "",
          },
          {
              text: "ERP ID",
              value: "erpId",
              sortable: false,
              default: "",
          },
          {
              text: "Total Number of Coupons",
              value: "totalNumberCoupons",
              sortable: false,
              default: "",
          },
          {
              text: "Actions",
              value: "action",
              sortable: false,
              default: "",
          },
      ];

      private inactiveSchemes: boolean = false;

      get formValidate(): Vue & { validate: () => boolean } {
          return this.$refs.form as Vue & { validate: () => boolean };
      }
      get formReset(): Vue & { reset: () => void } {
          return this.$refs.form as Vue & { reset: () => void };
      }
      get formResetValidation(): Vue & { resetValidation: () => void } {
          return this.$refs.form as Vue & { resetValidation: () => void };
      }

public showCommunicationError(err: any) {
  this.snackbarColor = "red";
  this.snackbar = true;
  this.message = window.navigator.onLine
    ? `Some Error occured`
    : `Cannot reach server at the moment`;
}
public getStatus(value: any): Promise<boolean> {
  return new Promise<boolean>((res) => {
    CouponSchemeService.GetSchemeStatus(value)
      .then((response) => {
        const data = response.data;
        console.log(data);
        this.info = data;
        res(true);
      })
      .catch((error): any => {
        console.log(error);
      });
  });
}
public clearForm(): void {
  this.info = Object.assign({}, this.defaultItem);
}
public openDrawer(value: string): void {
  this.clearForm();
  this.sideDrawer = true;
  this.formResetValidation.resetValidation();
}
public editCoupon(scheme: ICouponScheme): void {
  this.getStatus(scheme.id);
  this.openDrawer("");
  this.sidedrawerTitle = scheme.name;
}
public closeSideDrawer(value: boolean): void {
  this.hideWindowScrollBar(value);
  this.sideDrawer = value;
}

public viewScheme(value: ICouponScheme): void {
  this.createScheme = false;
}

      public async refreshSchemesList() {
          this.inactiveSchemes
              ? await this.getInActiveScheme(1)
              : await this.getSchemes(1);
      }
      public openCreateTab(value: string): void {
          let createGameURL: string = `${oldPortalUrl}FieldAssistPOC/CouponManagement/SchemeCreate`;
          window.open(createGameURL, "_blank");
      }
      public closeSnackbar(value: boolean): void {
          this.snackbar = value;
      }

      public async getSchemes(value: number): Promise<boolean> {
          this.pageLoading = true;
          return new Promise<boolean>((res) => {
              CouponSchemeService.GetCouponScheme(value)
                  .then((response) => {
                      console.log(response);
                      this.Schemes = response.data.coupon;
                      this.pages = response.data.pages;
                      this.totalScheme = response.data.total;
                      this.pageLoading = false;
                      if (this.Schemes.length > 0) {
                          this.showSchemesList = true;
                      } else {
                          this.showSchemesList = false;
                          //this.description = this.normSearch.length == 0 ? "No Scheme Found" : "No matching result found" ;
                          //this.subdescription = this.normSearch.length == 0 ? "Please create a Scheme to begin with" : "Make sure you typed correctly or try finding in inactive schemes.";
                      }
                      res(true);
                  })
                  .catch((error) => {
                      this.showCommunicationError(error);
                      console.log(error);
                      this.pageLoading = false;
                  });
          });
      }
      public changePage(value: number): void {
          this.page = value;
          this.inactiveSchemes
              ? this.getInActiveScheme(value)
              : this.getSchemes(value);
      }
      public Deactivate(scheme: ICouponScheme, commandIndex: number) {
          this.selectedGameId = scheme.id;
          this.selectedActionMenu = commandIndex;

  this.confirmationPopupTitle = "Confirm Deactivation";
  this.confirmationPopupSubtitle =
    "Are you sure you wish to deactivate the scheme?";
  this.confirmationPopupText = "";
  this.cancelBtnText = "Cancel";
  this.confirmBtnText = "Confirm";
  this.confirmationPopup = true;
}
public SchemeView(request: ICouponScheme): void {
  this.$router.push({
    name: "SchemeView",
    params: { id: request.id.toString() },
  });
}
public SchemeList(): void {
  this.$router.push({ name: "SchemeCreate" });
}
public confirmAction(value: any): void {
  CouponSchemeService.UpdateEndDate(value)
    .then((res) => {
      this.snackbarColor = "red";
      this.message =
        "Scheme deactivated and End Date updated to current date";
      this.actionablePopup();
      this.refreshSchemesList();
    })
    .catch((err) => {
      this.showCommunicationError(err);
      console.log(err);
    });
}
public actionablePopup() {
  this.snackbar = true;
  this.confirmationPopup = false;
  this.showSchemesList = true;
}

public async getInActiveScheme(page: number): Promise<boolean> {
  this.pageLoading = true;
  return new Promise<boolean>((res) => {
    CouponSchemeService.getInactiveCouponScheme(page)
      .then((response) => {
        this.Schemes = response.data.coupon;
        this.pages = response.data.pages;
        this.totalScheme = response.data.total;
        this.pageLoading = false;
        res(true);
      })
      .catch((error) => {
        this.showCommunicationError(error);
        console.log(error);
        this.pageLoading = false;
      });
  });
}
public async Status(scheme: ICouponScheme) {
  this.selectedGameId = scheme.id;
  await this.getStatus(this.selectedGameId);
  this.dialog = true;
}

      public switchSchemes() {
          if (this.inactiveSchemes) {
              this.tableHeaders = [
                  {
                      text: "Scheme Name",
                      value: "name",
                      sortable: true,
                      default: "",
                  },
                  {
                      text: "End Date",
                      value: "endDate",
                      sortable: false,
                      default: "",
                  },
                  {
                      text: "ERP ID",
                      value: "erpId",
                      sortable: false,
                      default: "",
                  },
                  {
                      text: "Total Number of Coupons",
                      value: "totalNumberCoupons",
                      sortable: false,
                      default: "",
                  },
                  {
                      text: "Actions",
                      value: "action",
                      sortable: false,
                      default: "",
                  },
              ];
              this.getInActiveScheme(1);
          } else {
              this.tableHeaders = [
                  {
                      text: "Scheme Name",
                      value: "name",
                      sortable: true,
                      default: "",
                  },
                  {
                      text: "End Date",
                      value: "endDate",
                      sortable: false,
                      default: "",
                  },
                  {
                      text: "ERP ID",
                      value: "erpId",
                      sortable: false,
                      default: "",
                  },
                  {
                      text: "Total Number of Coupons",
                      value: "totalNumberCoupons",
                      sortable: false,
                      default: "",
                  },
                  {
                      text: "Actions",
                      value: "action",
                      sortable: false,
                      default: "",
                  },
              ];
              this.getSchemes(1);
          }
      }
      async created() {
          this.pageLoading = true;
          this.getSchemes(1).then((_) => _);
          //this.GetMenuActions().then((_) => _);
      }
      private hideWindowScrollBar(hideIt: boolean) {
          window.document.documentElement.style.overflowY = hideIt
              ? "hidden"
              : "scroll";
      }
  }
